import React from "react";
import Logo from "../../images/logo.svg";
import styles from "./Navbar.module.scss";

const Navbar = () => {
  return (
    <div className={styles.navBar__box}>
      <div className={styles.navBar}>
        <img src={Logo} alt="logo" />
        <div className={styles.navBar__buttons}>
          <button className={styles.navBar__button}>Login</button>
          <button className={styles.navBar__button}>Sign Up</button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
