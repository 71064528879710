import React from "react";
import styles from "./Solutions.module.scss";

import Check from "../../images/check.svg";
import Hand from "../../images/landingpage-hands.7c6194c.png";
import CheckoutModal from "../../images/checkout_modal.ab7f520.png";
const Solutions = () => {
  return (
    <div className={styles.solutions}>
      <div className={styles.solutions__intro}>
        <div className={styles.solutions__intro__tag}>
          <h3>OUR SOLUTION</h3>
        </div>
        <h2 className={styles.solutions__intro__heading}>
          Your payment experience but with even forward-thinking{" "}
          <b>solutions</b>
        </h2>
        <p className={styles.solutions__intro__desc}>
          Empower your customers to make payments, by using Blaaiz. Just
          plug-and-play and you're good to go.
        </p>
      </div>

      <div className={styles.solutions__main}>
        <div
          className={`${styles.solutions__main__solution} ${styles.solutions__main__solution__one}`}
        >
          <h4 className={styles.solutions__main__solution__heading}>
            Virtual Accounts for all your customers
          </h4>
          <p className={styles.solutions__main__solution__desc}>
            Create virtual accounts for your customers at no extra cost.
          </p>
          <div className={styles.solutions__main__solution__one__cards}>
            <div className={styles.solutions__main__solution__one__card1}>
              <p>Virtual Card</p>
            </div>
            <div className={styles.solutions__main__solution__one__card2}>
              <p>Virtual Card</p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.solutions__main__solution} ${styles.solutions__main__solution__two}`}
        >
          <h4 className={styles.solutions__main__solution__heading}>
            Easy, Instant Payouts to your customers.
          </h4>
          <p className={styles.solutions__main__solution__desc}>
            Pay one customer or pay as many as you want with Blaaiz.
          </p>
          <div className={styles.solutions__main__solution__point}>
            <div className={styles.solutions__main__solution__point__check}>
              <img src={Check} alt="check" />
            </div>
            <p>An editable validator to make changes</p>
          </div>

          <div className={styles.solutions__main__solution__point}>
            <div className={styles.solutions__main__solution__point__check}>
              <img src={Check} alt="check" />
            </div>
            <p>Detailed progress of all payouts</p>
          </div>
        </div>
        <div
          className={`${styles.solutions__main__solution} ${styles.solutions__main__solution__three}`}
        >
          <img
            className={styles.solutions__main__solution__three__hand}
            src={Hand}
            alt="hand"
          />
          <h4 className={styles.solutions__main__solution__heading}>
            Send links, and receive payments easily
          </h4>
          <p className={styles.solutions__main__solution__desc}>
            Create payment pages quickly by adding simple fields and share with
            customers.
          </p>
          <div className={styles.solutions__main__solution__point}>
            <div className={styles.solutions__main__solution__point__check}>
              <img src={Check} alt="check" />
            </div>
            <p>Easily customizable payment pages</p>
          </div>

          <div className={styles.solutions__main__solution__point}>
            <div className={styles.solutions__main__solution__point__check}>
              <img src={Check} alt="check" />
            </div>
            <p>Sharable to all or selected customers.</p>
          </div>
        </div>
        <div
          className={`${styles.solutions__main__solution} ${styles.solutions__main__solution__four}`}
        >
          <div>
            <h4 className={styles.solutions__main__solution__heading}>
              Checkouts when and how you want it
            </h4>
            <p className={styles.solutions__main__solution__desc}>
              Multiple payment so your customers never find it hard making
              payments
            </p>
            <div className={styles.solutions__main__solution__point}>
              <div className={styles.solutions__main__solution__point__check}>
                <img src={Check} alt="check" />
              </div>
              <p>Multiple payment methods</p>
            </div>

            <div className={styles.solutions__main__solution__point}>
              <div className={styles.solutions__main__solution__point__check}>
                <img src={Check} alt="check" />
              </div>
              <p>Quick Responses</p>
            </div>
          </div>
          <img
            className={styles.solutions__main__solution__four__checkoutModal}
            src={CheckoutModal}
            alt="checkout modal"
          />
        </div>
      </div>
    </div>
  );
};

export default Solutions;
