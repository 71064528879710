import React from "react";
import styles from "./Footer.module.scss";

import Logo from "../../images/logo.svg";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footer__main}>
        <img src={Logo} alt="logo" />

        <div className={styles.footer__main__group}>
          <h4>Company</h4>
          <p>Terms & Service</p>
          <p>Privacy Policy</p>
        </div>

        <div className={styles.footer__main__group}>
          <h4>Help</h4>
          <p>How To</p>
          <p>Contact Us</p>
        </div>
      </div>

      <div className={styles.footer__copyright}>
        <p>© 2023 All Rights Reserved. Powered by 78 Financials</p>
      </div>
    </div>
  );
};

export default Footer;
