import Navbar from "./components/Navbar/Navbar";
import styles from "./App.module.scss";
import "./styles/index.scss";

//images
import BackgroundSketch from "./images/background.svg";
import Dashboard from "./images/merchant_dashboard.bbe241b.png";
import Tick from "./images/check.svg";
import ReliableIcon from "./images/reliable.svg";
import SupportIcon from './images/support.svg';
import SeemlessIcon from './images/seemless.svg'

//components
import Solutions from "./sections/Solutions/Solutions";
import Footer from "./sections/Footer/Footer";
function App() {
  return (
    <div className={styles.home}>
      <Navbar />
      <div className={styles.home__intro}>
        <img
          src={BackgroundSketch}
          className={styles.home__intro__bgOne}
          alt="background sketch"
        />
        <img
          src={BackgroundSketch}
          className={styles.home__intro__bgTwo}
          alt="background sketch"
        />
        <div className={styles.home__intro__main}>
          <h2>Meet the next generation payments</h2>
          <p>
            With Blaaiz, your customers have access to as many payment options
            as possible at a very affordable rate
          </p>
          <button>Create Account</button>
          <div className={styles.home__intro__main__dashboardBox}>
            <img
              className={styles.home__intro__main__dashboard}
              src={Dashboard}
              alt="merchant dashboard"
            />
          </div>
        </div>
      </div>
      <div className={styles.home__whyUs__box}>
        <div className={styles.home__whyUs}>
          <div className={styles.home__whyUs__intro}>
            <div className={styles.home__whyUs__intro__main}>
              <div className={styles.home__whyUs__intro__heading}>
                <h3>WHY BLAAIZ?</h3>
              </div>
              <h4 className={styles.home__whyUs__intro__desc}>
                Easy and delightful experience
              </h4>
            </div>

            <div className={styles.home__whyUs__intro__other}>
              <p className={styles.home__whyUs__intro__other__text}>
                Grow revenues, delight your customers when you use Blaaiz to
                address all your payment concerns in your business.
              </p>

              <div className={styles.home__whyUs__intro__other__services}>
                <div className={styles.home__whyUs__intro__other__service}>
                  <div
                    className={styles.home__whyUs__intro__other__service__check}
                  >
                    <img src={Tick} />
                  </div>
                  <p>Card</p>
                </div>

                <div className={styles.home__whyUs__intro__other__service}>
                  <div
                    className={styles.home__whyUs__intro__other__service__check}
                  >
                    <img src={Tick} />
                  </div>
                  <p>Transfer</p>
                </div>

                <div className={styles.home__whyUs__intro__other__service}>
                  <div
                    className={styles.home__whyUs__intro__other__service__check}
                  >
                    <img src={Tick} />
                  </div>
                  <p>Bank</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.home__whyUs__points}>
            <div className={styles.home__whyUs__point}>
              <div className={styles.home__whyUs__point__icon}>
                <img src={ReliableIcon} alt="reliable" />
              </div>
              <h3>Reliable</h3>
              <p>
                With Blaaiz, you can be sure of less transaction errors and
                instant payouts
              </p>
            </div>

            <div className={styles.home__whyUs__point}>
              <div className={styles.home__whyUs__point__icon}>
                <img src={SupportIcon} alt="reliable" />
              </div>
              <h3> 24/7 Support</h3>
              <p>
                You can be sure not to run into any errors but just incase you
                do, we’re always available to help
              </p>
            </div>

            <div className={styles.home__whyUs__point}>
              <div className={styles.home__whyUs__point__icon}>
                <img src={SeemlessIcon} alt="reliable" />
              </div>
              <h3> Seamless</h3>
              <p>
                Blaaiz is easy to use, and provides a refreshing customer
                experience on your every use.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Solutions />
      <Footer />
    </div>
  );
}

export default App;
